/*
 * form-button.less - General button style overrides
 */

.btn.active,
.btn:active,
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  box-shadow: none;
}
