/*
 * page.less - Style top level page items
 */

#tabs {
  // Ensure space below navbar is consistent
  margin-top: 0;
  padding: (@padding-large-vertical / 2) 0 0 0;

  // Give the page contents square corners
  border-radius: 0;

  // Provide smooth scrolling on iOS
  -webkit-overflow-scrolling: touch;
}

// Make the page contents full height minus navbar, padding and border
#main > .row-offcanvas {
  height: calc(100vh - @navbar-height - 8px - @padding-large-vertical) !important;
}

#tabcontent > .tab-pane > .panel {
  margin-left: @form-outer-margin;
  margin-right: @form-outer-margin;
  margin-bottom: @form-outer-margin;
}

// Ensure the correct colour is used for the form title
.tab-pane > .panel > .panel-heading a {
  color: inherit;
}

// Ensure panel does not extend outside page
#tabs .panel-body.row {
  margin: 0;

  > div {
    padding: 0;
  }
}

// Remove the divider and space between the title/toolbar and the contents
.panel-default > .panel-heading {
  border-color: transparent;
}
.panel-body {
  padding-top: 0;
}

// Add box shadow to the main page contents
#tabcontent > .tab-pane > .panel {
  box-shadow: @page-components-box-shadow;
}

// Remove border from around the main page contents
#tabcontent > .tab-pane > .panel {
  border: none;
}

// Display the tabs full screen on small screens
@media (max-width: @screen-xs-max) {
  html {
    overflow: clip !important;
  }
  #tabs {
    top: -60px;
    height: calc(100% + 60px) !important;
  }
}
