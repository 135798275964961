/*
 * menu.less - Style the main menu on the left
 */

// Allow customization of the menu colours
#menu {
  background-color: transparent;
  color: @menu-color;

  .content-box {
    background-color: @menu-bg;
  }

  img {
    filter: @menu-icon-color-filter;
  }
}

// Hide unrequired parts of the menu tree
#menu {
  border: none;

  div.scrollbar.responsive {
    display: none;
  }

  col.selection-state {
    width: 0 !important;
  }
}

// Remove the gap between the menu and the navbar
#menu {
  margin-top: 0;
}

// Make hiding and showing the sidebar smooth except on small screens
#tabs,
#main > .row-offcanvas {
  transition: all 0.25s ease-in-out;

  @media (max-width: @screen-xs-max) {
    transition: unset;
  }
}

// Move the scrollbar inside the menu
#menu {
  overflow-y: unset;

  .content-box {
    height: 100%;
  }
}

// Round the corners on the menu
#menu .content-box {
  border-radius: @border-radius-large;
}

// Add even space around the menu
#menu {
  height: calc(100% - (@padding-large-vertical / 2)) !important;
  padding: (@padding-large-vertical / 2) (@padding-large-vertical / 2) (@padding-large-vertical / 2) @padding-large-vertical;

  @media (max-width: @screen-xs-max) {
    padding: (@padding-large-vertical / 2) @padding-large-vertical;
  }
}

// Add some space around the menu items
#menu .content-box .treeview {
  padding: @padding-base-vertical;
}

// Provide smooth scrolling on iOS
#menu {
  -webkit-overflow-scrolling: touch;
}

// Add indents to prefix
#menu {
  .tree-level-1 .prefix {
    padding-left: 1em;
  }
  .tree-level-2 .prefix {
    padding-left: 2em;
  }
  .tree-level-3 .prefix {
    padding-left: 3em;
  }
  .tree-level-4 .prefix {
    padding-left: 4em;
  }
  .tree-level-5 .prefix {
    padding-left: 5em;
  }
  .tree-level-6 .prefix {
    padding-left: 6em;
  }
}

// Move the expander to the right
#menu .expander {
  order: 1;
}

// Hide the favourite icon column
#menu .favorite {
  display: none;
}

// Add shadow to the menu
#menu > .content-box {
  box-shadow: @page-components-box-shadow;
}

// Make the scroll bars a better colour
#menu > .content-box {
  scrollbar-color: @gray-light transparent;
}

// Make submenu items less prominent
#menu {
  .tree-level-1 .prefix img {
    height: 1.4em;
    opacity: 0.6;
  }
  .tree-level-2 .prefix img {
    height: 1.2em;
    opacity: 0.5;
  }
  .tree-level-3,
  .tree-level-4,
  .tree-level-5,
  .tree-level-6 {
    .prefix img {
      height: 1em;
      opacity: 0.5;
    }
  }
}
