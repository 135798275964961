/*
 * toolbar.less - Styles for the toolbar displayed at the top of (sub)forms
 */

@import 'mixins/buttons';
@import 'mixins/tab-focus';
@import 'mixins/vendor-prefixes';

@toolbar-button-width: @input-icon-base + (@toolbar-button-padding-horizontal * 2) + 2px;
@toolbar-button-height: @input-icon-base + (@toolbar-button-padding-vertical * 2) + 2px;
@toolbar-btn-margin-vertical: (@navbar-height - @toolbar-button-height) / 2;
@toolbar-title-padding: (@navbar-height - @line-height-computed - (@toolbar-btn-margin-vertical * 2)) / 2;
@toolbar-icon-color-escaped: escape(@toolbar-icon-color);


// Allow configuration of the toolbar button border
.toolbar .btn {
  border-color: @toolbar-button-border-color;

  &:hover, &:focus {
    border-color: @toolbar-button-border-color;
    background-color: @toolbar-button-hover-color;
  }
}

// Use more of the available width
.toolbar .navbar {
  padding-left: @toolbar-button-padding-horizontal;
  padding-right: @toolbar-button-padding-horizontal;
}

// Reset the padding on the buttons
.toolbar .btn {
  padding: @toolbar-button-padding-vertical @toolbar-button-padding-horizontal;
}

// Adjust the size of the icons in the toolbar
.toolbar {
  img.icon {
    height: @input-icon-base;
  }

  .navbar-btn {
    margin-top: @toolbar-btn-margin-vertical;
    margin-bottom: @toolbar-btn-margin-vertical;
  }
}

// Keep the toolbar in the header at all screen sizes
.toolbar {
  .navbar-header,
  .navbar-right {
    margin-left: 0;
    margin-right: 0;
  }
}

// Use the small font for badges
.toolbar button > .badge {
  font-size: @font-size-tiny;
}

// Ensure dropdown menu text is visible
.toolbar .dropdown-menu {
  color: @dropdown-link-color;
}

// Set the colour of button icons
.toolbar .btn > img.icon {
  filter: @toolbar-icon-color-filter;
}

// Force rounded borders around toolbar buttons
.toolbar .btn {
  border-radius: @border-radius-small !important;
}

// Make the space on the sides of the toolbar consistent with vertical margin
.toolbar {
  .navbar-left .navbar-brand,
  .btn-toolbar {
    padding-left: 0;
    padding-right: 0;
  }
}

// Only resize the name part of the toolbar
.toolbar .container-fluid {
  display: flex;

  > .navbar-left {
    flex-grow: 1;
    flex-shrink: 1;

    > a > span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }

  > .navbar-right {
    flex-grow: 0;
    flex-shrink: 0;
  }
}

// Make the menu open caret appear as a button on the left
.toolbar {
  .navbar-left > a {
    // Allow space for the open menu button
    margin-left: @input-icon-base + (@toolbar-button-padding-horizontal * 2);

    > span:last-child {
      background-position: center center;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="@{toolbar-icon-color-escaped}" d="M 5.9902344,7.9902344 A 1.0001,1.0001 0 0 0 5.2929688,9.7070312 l 6.0000002,5.9999998 a 1.0001,1.0001 0 0 0 1.414062,0 l 6,-5.9999998 A 1.0001,1.0001 0 1 0 17.292969,8.2929688 L 12,13.585938 6.7070312,8.2929688 A 1.0001,1.0001 0 0 0 5.9902344,7.9902344 Z"/></svg>');
      background-repeat: no-repeat;
      border-color: @toolbar-button-border-color;
      border-style: solid;
      border-weight: 1px;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      font-size: @font-size-base;
      line-height: @line-height-base;
      width: @toolbar-button-width;
      height: @toolbar-button-height;
      margin-top: @toolbar-btn-margin-vertical;
      margin-bottom: @toolbar-btn-margin-vertical;
      padding: @toolbar-button-padding-vertical @toolbar-button-padding-horizontal;
    }
  }
}

// Make the close icon appear as a button
.toolbar {
  .close {
    background-position: center center;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="@{toolbar-icon-color-escaped}" d="m 17.980469,4.9902344 a 1.0001,1.0001 0 0 0 -0.6875,0.3027344 L 5.2929688,17.292969 a 1.0001,1.0001 0 1 0 1.4140624,1.414062 L 18.707031,6.7070312 A 1.0001,1.0001 0 0 0 17.980469,4.9902344 Z"/><path fill="@{toolbar-icon-color-escaped}" d="M 5.9902344,4.9902344 A 1.0001,1.0001 0 0 0 5.2929688,6.7070312 L 17.292969,18.707031 a 1.0001,1.0001 0 1 0 1.414062,-1.414062 L 6.7070312,5.2929688 A 1.0001,1.0001 0 0 0 5.9902344,4.9902344 Z"/></svg>');
    background-repeat: no-repeat;
    opacity: 1;

    margin-top: @toolbar-btn-margin-vertical;
    margin-bottom: @toolbar-btn-margin-vertical;
    padding: @toolbar-button-padding-vertical @toolbar-button-padding-horizontal;

    span {
      display: inline-block;
      visibility: hidden;
      font-size: @font-size-base;
      line-height: @line-height-base;
      width: @input-icon-base;
    }
  }
}

// Hide toolbar buttons depending on screen width
.toolbar .btn-toolbar {
  > .btn-group:nth-child(1) {
    // #switch - always display
    > #previous {
      @media (max-width: @screen-xs-max) {
        display: none !important;
      }
    }
    > .navbar-text {
      // record count
      @media (max-width: @screen-sm-max) {
        display: none !important;
      }
    }
    > #next {
      @media (max-width: @screen-xs-max) {
        display: none !important;
      }
    }
  }
  > .btn-group:nth-child(2) {
    #new_ {
      @media (max-width: @screen-sm-max) {
        display: none !important;
      }
    }
    #save {
      @media (max-width: @screen-sm-max) {
        display: none !important;
      }
    }
    // #reload - always display
  }
  > .btn-group:nth-child(3) {
    // attachments
    > .btn-group:not(.open) {
      @media (max-width: @screen-md-max) {
        display: none !important;
      }
    }
    #note {
      @media (max-width: @screen-md-max) {
        display: none !important;
      }
    }
  }
  > .btn-group:nth-child(4) {
    > .btn-group:nth-child(1):not(.open) {
      // #action
      @media (max-width: @screen-xs-max) {
        display: none !important;
      }
    }
    > .btn-group:nth-child(2):not(.open) {
      // #relate
      @media (max-width: @screen-xs-max) {
        display: none !important;
      }
    }
    > .btn-group:nth-child(3):not(.open) {
      // #print
      @media (max-width: @screen-md-max) {
        display: none !important;
      }
    }
    #email {
      @media (max-width: @screen-md-max) {
        display: none !important;
      }
    }
  }
}

// Style the record counter on the toolbar
.toolbar .navbar-text {
  background-color: @btn-default-bg;
  border: 1px solid @btn-default-border;
  max-height: @toolbar-button-height;
  margin: @toolbar-btn-margin-vertical -1px @toolbar-btn-margin-vertical -1px;
  padding: @toolbar-button-padding-vertical @toolbar-button-padding-horizontal;

  .badge {
    background-color: inherit;
    color: @toolbar-icon-color;
    font-size: @font-size-base;
    font-weight: normal;
    line-height: unset;
    padding: floor((@input-icon-base - @font-size-base - 2px) / 2);
    user-select: none;
  }
}

// Ensure the toolbar is sticky in the correct position
.tab-board nav.toolbar,
.tab-form nav.toolbar {
  top: -5px;
}
