/*
 * board-clean.less - Styles that clean up and enlarge items on boards
 */

// Hide the filter box
.board .board-action .filter-box .row:last-child {
  display: none !important;
}

// Add a message if there is nothing in the list
.board .table > tbody:empty::after {
  content: "Nothing to see here...";
  color: @board-action-label-color;
  display: block;
  margin: @padding-base-horizontal;
}

// Increase the font size of board inputs
.board .board-action > .panel-body >.screen-container > .content-box > .form {
  td.form-callto,
  td.form-date,
  td.form-datetime,
  td.form-email,
  td.form-float,
  td.form-integer,
  td.form-many2one,
  td.form-reference,
  td.form-sip,
  td.form-url {
    .form-control {
      font-size: 200%;
    }
  }
}


