/*
 * nav-tabs.less - Style nav-tabs to look like buttons
 */

// Remove the line below the nav-tabs
.nav-tabs {
  border-color: transparent;
  display: flex;
  justify-content: space-between;
  min-width: 100%;
}

// Style the active nav-tab as a button
.nav-tabs > li > a {
  border-radius: @border-radius-base;
  border-style: solid;
  border-width: 1px;
}
.nav-tabs > li.active {
  > a,
  > a:focus,
  > a:hover {
  }
}
