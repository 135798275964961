/*
 * dialog.less - Apply styles to dialog boxes
 */

// Center the items in the application error
.application-error {
  .modal-header,
  .modal-body,
  .modal-footer {
    text-align: center;
  }
}

// Increase the size of the icon
.application-error .icon {
    height: 75px;
}

// Center the items in the application error
.application-error {
  .modal-body > p {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
}
