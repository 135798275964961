/*
 * form-widgets.less - Styling for the different widgets on forms
 */

@addon-icon-color-escaped: escape(@addon-icon-color);

// Remove box shadow for form controls
.form-control {
  box-shadow: none;
}

td.form-binary {
  // Make binary fields take up the full available width
  .input-group {
    width: 100%;
  }

  // Leave space for the buttons
  input.form-control:last-of-type {
    padding-right: (@padding-xs-horizontal + (@input-icon-small + (@input-icon-padding-small * 2)) * 2);
  }
}

td.form-boolean {
  // Position checkbox centrally
  > .form-boolean {
    padding: (@padding-small-vertical + @form-padding-vertical) (@padding-xs-horizontal + @form-padding-horizontal) !important;

    > input[type="checkbox"] {
      margin-top: 0;
    }
  }
}

td > button[style*="width: 100%"]:not(.form-link) {
  // Make sure buttons do not overflow their space
  margin: @form-padding-vertical @form-padding-horizontal;
  height: @input-height-small;
  width: calc(100% - (@form-padding-horizontal * 2)) !important;
  padding: 0 !important;

  // Give them a border
  border-color: @brand-primary;

  // Do not display the icon
  img.icon {
    display: none !important;
  }
}

td.form-callto {
  // Change the icon to the skype icon
  img {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="@{addon-icon-color-escaped}" d="M12.03,16.53C9.37,16.53 8.18,15.22 8.18,14.24C8.18,13.74 8.55,13.38 9.06,13.38C10.2,13.38 9.91,15 12.03,15C13.12,15 13.73,14.43 13.73,13.82C13.73,13.46 13.55,13.06 12.83,12.88L10.46,12.29C8.55,11.81 8.2,10.78 8.2,9.81C8.2,7.79 10.1,7.03 11.88,7.03C13.5,7.03 15.46,7.94 15.46,9.15C15.46,9.67 15,9.97 14.5,9.97C13.5,9.97 13.7,8.62 11.74,8.62C10.77,8.62 10.23,9.06 10.23,9.69C10.23,10.32 11,10.5 11.66,10.68L13.42,11.07C15.34,11.5 15.83,12.62 15.83,13.67C15.83,15.31 14.57,16.53 12.03,16.53M18,6C20.07,8.04 20.85,10.89 20.36,13.55C20.77,14.27 21,15.11 21,16A5,5 0 0,1 16,21C15.11,21 14.27,20.77 13.55,20.36C10.89,20.85 8.04,20.07 6,18C3.93,15.96 3.15,13.11 3.64,10.45C3.23,9.73 3,8.89 3,8A5,5 0 0,1 8,3C8.89,3 9.73,3.23 10.45,3.64C13.11,3.15 15.96,3.93 18,6M8,5A3,3 0 0,0 5,8C5,8.79 5.3,9.5 5.8,10.04C5.1,12.28 5.63,14.82 7.4,16.6C9.18,18.37 11.72,18.9 13.96,18.2C14.5,18.7 15.21,19 16,19A3,3 0 0,0 19,16C19,15.21 18.7,14.5 18.2,13.96C18.9,11.72 18.37,9.18 16.6,7.4C14.82,5.63 12.28,5.1 10.04,5.8C9.5,5.3 8.79,5 8,5Z"/></svg>');
    background-repeat: no-repeat;
    background-size: @input-icon-small;
    padding-left: @input-icon-small;
  }

  // Move the icon over when the text input is hidden
  input[style="display: none;"] ~ .input-group-btn {
    position: relative;
  }
}

td.form-date,
td.form-datetime {
  // Hide the calendar button in readonly fields
  .readonly .datepickerbutton {
    display: none;

    ~ input {
      padding-left: @padding-xs-horizontal !important;
    }
  }

  // TODO: Also hide the calendar button if input[readonly]
}

td.form-email {
  // Change the icon to an email icon
  img {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="@{addon-icon-color-escaped}" d="M19.07 13.88L13 19.94V22H15.06L21.12 15.93M22.7 13.58L21.42 12.3C21.32 12.19 21.18 12.13 21.04 12.13C20.89 12.14 20.75 12.19 20.65 12.3L19.65 13.3L21.7 15.3L22.7 14.3C22.89 14.1 22.89 13.78 22.7 13.58M11 18H4V8L12 13L20 8V10H22V6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H11V18M20 6L12 11L4 6H20Z"/></svg>');
    background-repeat: no-repeat;
    background-size: @input-icon-small;
    padding-left: @input-icon-small;
  }

  // Move the icon over when the text input is hidden
  input[style="display: none;"] ~ .input-group-btn {
    position: relative;
  }
}

td.form-float,
td.form-integer {
  // Always show the input[type="number"] field instead of the text field
  input[type="number"] {
    display: block !important;
  }
  input[type="text"] {
    display: none !important;
  }
}

td.form-group_ {
  // Groups with no title should not have an effect on positioning
  > .form-group_ {
    padding: 0 !important;
  }

  // Groups with a title should have a border around themselves
  > .form-group_ {
    > legend {
      background-color: @panel-default-heading-bg;
      border: 1px solid @panel-default-border;
      font-size: @font-size-base !important;
      margin: @form-padding-vertical @form-padding-horizontal 0 @form-padding-horizontal !important;
      padding: @panel-heading-padding;
      width: calc(100% - (@form-padding-horizontal * 2));
    }

    > legend ~ table {
      border-left: 1px solid @panel-default-border;
      border-right: 1px solid @panel-default-border;
      border-bottom: 1px solid @panel-default-border;
      border-collapse: initial;
      margin: 0 @form-padding-horizontal @form-padding-vertical @form-padding-horizontal;
      width: calc(100% - (@form-padding-horizontal * 2)) !important;
    }
  }

  // Remove the space below the grouped items
  > .form-group_ > table {
    display: table !important;
  }

  // Centre items in centered groups
  &[style*="center"] > .form-group_ > * {
    margin-left: auto;
    margin-right: auto;
  }
}

td.form-label {
  vertical-align: unset !important;

  label {
    display: inline-block;
    height: @input-height-small;
    line-height: @line-height-small;
    margin: 0;
    padding: (@padding-small-vertical + @form-padding-vertical) (@padding-xs-horizontal + @form-padding-horizontal) !important;
  }

  // Centre items in centered labels
  &[style*="center"] > .form-label {
    width: 100%;
    text-align: center;
  }
}

td.form-link {
  .btn-link {
    border: 1px solid @btn-default-color;
    border-radius: @input-border-radius-small;
    margin: @nav-tabs-padding-vertical;
    width: auto !important;

    > div {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="@{addon-icon-color-escaped}" version="1.1" id="svg501"><path d="M 5,5 C 3.3549904,5 2,6.3549904 2,8 v 11 c 0,1.64501 1.3549904,3 3,3 h 11 c 1.64501,0 3,-1.35499 3,-3 v -6 a 1.0001,1.0001 0 1 0 -2,0 v 6 c 0,0.564129 -0.435871,1 -1,1 H 5 C 4.4358706,20 4,19.564129 4,19 V 8 C 4,7.4358706 4.4358706,7 5,7 h 6 a 1.0001,1.0001 0 1 0 0,-2 z" id="path495" /><path d="m 15,2 a 1.0001,1.0001 0 1 0 0,2 h 5 v 5 a 1.0001,1.0001 0 1 0 2,0 V 3 A 1.0001,1.0001 0 0 0 21,2 Z" id="polyline497" /><path d="m 20.980469,1.9902344 a 1.0001,1.0001 0 0 0 -0.6875,0.3027344 L 9.2929688,13.292969 a 1.0001,1.0001 0 1 0 1.4140622,1.414062 l 11,-10.9999998 A 1.0001,1.0001 0 0 0 20.980469,1.9902344 Z" id="line499" /></svg>');
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: left;
      background-size: 18px 18px;
      margin-right: -10px;
      padding-left: 24px;
    }
  }

  // Move the counters to the top right of the tabs
  .badge {
    background-color: @brand-danger;
    font-size: @font-size-tiny;
    width: fit-content;
    min-width: auto;
    position: relative;
    top: -16px;
    right: -8px;
    z-index: 4;
  }
}

td.form-many2one .dropdown-menu {
  // Limit the height of the dropdown menu
  max-height: 320px;
  overflow: hidden auto;
}

td.form-many2many,
td.form-one2many {
  // Add spacing using margin instead of padding
  > * {
    padding: 0 !important;
    margin: @form-padding-vertical @form-padding-horizontal;
    width: calc(100% - (@form-padding-horizontal * 2)) !important;
  }

  // Remove padding around content
  > .panel > .panel-body {
    padding: 0;
  }

  // Hide checkboxes when readonly
  .readonly .tree > colgroup > col.selection-state {
    width: 0 !important;
  }
}

td.form-notebook {
  // Horizonally scroll the tabs if they overflow
  > .form-notebook > ul {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
  }

  // Ensure the tabs do not wrap
  > .form-notebook {
    white-space: nowrap;
    > .nav-tabs {
      > li {
        display: inline-block;
        float: none;
      }
    }
  }

  // Add some padding around the tabs
  > .form-notebook > .nav-tabs {
    margin: 0;
    padding-top: @nav-tabs-padding-vertical;
    padding-bottom: @nav-tabs-padding-vertical;
    padding-left: @nav-tabs-padding-horizontal;
    padding-right: @nav-tabs-padding-horizontal;
  }
}

td.form-password {
  // Use icon for the button instead of Show/Hide text
  button {
    background-position: center;
    background-repeat: no-repeat;
    background-size: @input-icon-small;

    overflow: hidden;
    text-indent: 200%;
    white-space: nowrap;
  }

  // Set the show password icon
  input[type="password"] ~ .input-group-btn button {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="@{addon-icon-color-escaped}" d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z"/></svg>');
  }

  // Set the hide password icon
  input[type="text"] ~ .input-group-btn button {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="@{addon-icon-color-escaped}" d="M2,5.27L3.28,4L20,20.72L18.73,22L15.65,18.92C14.5,19.3 13.28,19.5 12,19.5C7,19.5 2.73,16.39 1,12C1.69,10.24 2.79,8.69 4.19,7.46L2,5.27M12,9A3,3 0 0,1 15,12C15,12.35 14.94,12.69 14.83,13L11,9.17C11.31,9.06 11.65,9 12,9M12,4.5C17,4.5 21.27,7.61 23,12C22.18,14.08 20.79,15.88 19,17.19L17.58,15.76C18.94,14.82 20.06,13.54 20.82,12C19.17,8.64 15.76,6.5 12,6.5C10.91,6.5 9.84,6.68 8.84,7L7.3,5.47C8.74,4.85 10.33,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C12.69,17.5 13.37,17.43 14,17.29L11.72,15C10.29,14.85 9.15,13.71 9,12.28L5.6,8.87C4.61,9.72 3.78,10.78 3.18,12Z"/></svg>');
  }
}

td.form-reference {
  // Try and avoid the fields wrapping or overflowing
  input {
    min-width: 8em !important;
  }
  select {
    min-width: 6em !important;
  }
}

td.form-selection {
  // Don't display the dropdown icon on disabled selection fields
  select[disabled],
  select.disabled {
    appearance: none;
    padding-top: 0;
  }
}

td.form-sip {
  // Change the icon to a voip icon
  img {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="@{addon-icon-color-escaped}" d="M13,17V19H14A1,1 0 0,1 15,20H22V22H15A1,1 0 0,1 14,23H10A1,1 0 0,1 9,22H2V20H9A1,1 0 0,1 10,19H11V17H13M23.7,7.67C23.88,7.85 24,8.09 24,8.37C24,8.65 23.89,8.9 23.71,9.08L21.23,11.56C21.05,11.74 20.8,11.85 20.5,11.85C20.25,11.85 20,11.75 19.82,11.57C19,10.84 18.13,10.21 17.15,9.72C16.82,9.56 16.59,9.21 16.59,8.82V5.72C15.14,5.25 13.59,5 12,5C10.4,5 8.85,5.25 7.4,5.73V8.83C7.4,9.23 7.17,9.57 6.84,9.73C5.87,10.22 4.97,10.84 4.18,11.58C4,11.75 3.75,11.86 3.5,11.86C3.2,11.86 2.95,11.75 2.77,11.57L0.29,9.09C0.11,8.91 0,8.66 0,8.38C0,8.1 0.11,7.85 0.29,7.67C3.34,4.78 7.46,3 12,3C16.53,3 20.65,4.78 23.7,7.67M11,10V15H10V10H11M12,10H15V13H13V15H12V10M14,12V11H13V12H14Z"/></svg>');
    background-repeat: no-repeat;
    background-size: @input-icon-small;
    padding-left: @input-icon-small;
  }

  // Move the icon over when the text input is hidden
  input[style="display: none;"] ~ .input-group-btn {
    position: relative;
  }
}

td.form-url {
  // Move the icon over when the text input is hidden
  input[style="display: none;"] ~ .input-group-btn {
    position: relative;
  }
}

// Change the icon for telephone urls
a[href^='tel:'] > img {
  padding-left: unit(@line-height-base, em);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="@{addon-icon-color-escaped}" d="M4,3A1,1 0 0,0 3,4A17,17 0 0,0 20,21A1,1 0 0,0 21,20V16.5A1,1 0 0,0 20,15.5C18.75,15.5 17.55,15.3 16.43,14.93C16.08,14.82 15.69,14.9 15.41,15.17L13.21,17.37C10.38,15.93 8.06,13.62 6.62,10.78L8.82,8.57C9.1,8.31 9.18,7.92 9.07,7.57C8.7,6.45 8.5,5.25 8.5,4A1,1 0 0,0 7.5,3H4M15,3V4.5H18.5L13,10L14,11L19.5,5.5V9H21V3H15Z"/></svg>');
  background-size: unit(@line-height-base, em) unit(@line-height-base, em);
  background-repeat: no-repeat;
}
