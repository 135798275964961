/*
 * tree-editable.less - Style editable tree views
 */

// Make the input fields the full height of the lines
.tree .editable .form-control {
  height: @line-height-computed + (@table-condensed-cell-padding * 2);
}

// Ensure the corners of the input fields are not rounded
.tree .editable .form-control {
  border-radius: 0;
}
