/*
 * login.less - Styles login screen
 */

// Only display the body and login box on the screen
.login > :not(.modal) {
  display: none !important;
}

// Set background colour / image
.login {
  background-image: @login-background-image;
  background-size: cover;
  height: 100vh;

  .modal {
    background-color: @login-background-image-overlay;
  }
}

// Make the login buttons take up the full width
.login .modal-footer {
  display: flex;

  .btn {
    width: 100%;

    + .btn {
      margin: @padding-small-vertical 0;
    }
  }
}

// Reduce the width of the login dialog
@media (min-width: 768px) {
  .login .modal-md {
    width: 400px;
  }
}

// Hide the version number
.login .modal .modal-title small {
  display: none;
}

// Remove the panel styling around the login details
.login .modal .modal-body .panel {
  border: none;
  box-shadow: none;
  margin: 0;

  > .panel-body {
    padding: 0;
  }
}

// Don't pad the empty footer on ProCentral 6.6+
.login .modal .modal-footer:empty {
  padding: 0;
}

// Increase the space above the button on ProCentral 6.6+
.login .modal .modal-body .btn {
  margin-top: @font-size-base + (@padding-base-vertical * 2);
}

// Set the brand on the login screen
.login .modal .modal-title {
  background-image: @login-logo-image;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: if((@login-logo-image = none), unset, 64px);
  text-indent: if((@login-logo-image = none), 0, -262144px);
}

// Clean up the shadow and border around the login box
.login .modal-content {
  border: none;
  box-shadow: 5px 5px 15px @gray-lighter;
}

// Add ProCentral logo
.login .login-logo {
  background-image: url(/images/procentral-logo-dark.svg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 120px;
  width: 120px;
}

// Center the contents of the login footer
.login .login-footer {
  text-align: center;
}
.login .login-logo {
  margin: auto;
}

// Ensure the footer is spaced appropriately
.login .login-footer {
  margin-bottom: 2em;

  > p {
    margin-top: 1em;
  }
}

// Drop the footer to the bottom of the screen
.login .modal {
  display: flex;
  flex-direction: column;

  .modal-dialog {
    flex-grow: 1;
  }
}

// Style the footer version number
.login .login-footer .version {
  font-size: 70%;
  margin-top: 0;
  opacity: 0.5;
}

