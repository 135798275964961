/*
 * navbar.less - Style the navbar displayed at the top of the screen
 */

@navbar-icon-color-escaped: escape(@navbar-icon-color);

// Remove the bottom margin from all navbars
.navbar {
  margin-bottom: 0;
}

// Ensure the toggle doesn't increase the height of the navbar
.navbar-toggle {
  padding-top: (@navbar-height - @line-height-computed - 16px - 2px) / 2;
  padding-bottom: (@navbar-height - @line-height-computed - 16px - 2px) / 2;
}

// Convert the navbar toggle into an arrow
.navbar-toggle {
  border: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="@{navbar-icon-color-escaped}" d="m 8,14 c -2.7495791,0 -5,2.250421 -5,5 v 2 a 1.0001,1.0001 0 1 0 2,0 v -2 c 0,-1.668699 1.3313011,-3 3,-3 h 8 c 1.668699,0 3,1.331301 3,3 v 2 a 1.0001,1.0001 0 1 0 2,0 v -2 c 0,-2.749579 -2.250421,-5 -5,-5 z"/><path fill="@{navbar-icon-color-escaped}" d="M 12,2 C 9.2504209,2 7,4.2504209 7,7 c 0,2.7495791 2.2504209,5 5,5 2.749579,0 5,-2.2504209 5,-5 0,-2.7495791 -2.250421,-5 -5,-5 z m 0,2 c 1.668699,0 3,1.3313011 3,3 0,1.6686989 -1.331301,3 -3,3 C 10.331301,10 9,8.6686989 9,7 9,5.3313011 10.331301,4 12,4 Z"/></svg>');
  background-repeat: no-repeat;
  background-size: @input-icon-base;
  background-position: center;
  transition: all 0.25s ease-in-out;

  .caret {
    color: transparent;
  }
}

// Always use a clear the navbar toggle background
.navbar-inverse .navbar-toggle {
  &:focus, &:hover {
    background-color: transparent;
  }
}

// Ensure the logout button doesn't increase the height of the navbar
#user-logout > a {
  padding-top: (@navbar-height - @line-height-computed) / 2;
  padding-bottom: (@navbar-height - @line-height-computed) / 2;
}

// Position the search box centrally
#global-search {
  margin-top: (@navbar-height - @input-height-small) / 2;
  margin-bottom: (@navbar-height - @input-height-small) / 2;
}

// Hide the search box if the user-favourites is not there
#user-favorites:empty ~ input {
  display: none;
}

// Ensure the user-favourites button has a border
#user-favorites .btn {
  border-color: @btn-primary-border;
}

// Dont let tabs overlap the space below the navbar
.navbar .nav-tabs > li > a {
  border-bottom: none;

  &:focus,
  &:hover {
    border-bottom: none;
  }
}

// Ensure there is a border around the search box
#global-search-entry {
  border-top-style: solid;
  border-top-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  border-color-top: @input-border;
  border-color-left: @input-border;
  border-color-right: @input-border;
}

// Add styling to the main navbar
nav.navbar-static-top {
  padding: @padding-large-vertical @padding-large-vertical (@padding-large-vertical / 2) @padding-large-vertical;

  > .container-fluid {
    background-color: @navbar-main-bg;
    border-radius: @border-radius-large;
    padding-top: 0;
    padding-bottom: 0;
  }
}

// Ensure the logout button corners match the navbar
#user-logout {
  border-top-right-radius: @border-radius-large;
  border-bottom-right-radius: @border-radius-large;
}

// Remove the space after the brand in the navbar
.navbar .navbar-brand {
  padding-right: 0;
}

// Fix the height of the navbar
.navbar .navbar-brand {
  height: @navbar-height - 2px;
}

// Use an image in the main navbar
#title {
  background-image: @brand-logo-image;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  margin-left: 0.5em;
  height: 38px;
  width: if((@brand-logo-image = none), unset, @brand-logo-image-width);
  top: if((@brand-logo-image = none), 0, -6px);
  position: relative;
  text-indent: if((@brand-logo-image = none), 0, -262144px);
}

// Add box shadow around the main navbar
nav.navbar > .container-fluid {
  box-shadow: @page-components-box-shadow;
}

// Scroll the tabs instead of wrapping them
#tablist {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-color: @gray-light transparent;
  scrollbar-width: thin;

  > li {
    float: none;
    display: inline-block;
  }
}

// Hide the global search box
#global-search {
  display: none;
}

// Hide the button to toggle the menu on small screens
.navbar .navbar-brand {
  @media (max-width: @screen-xs-max) {
    padding-left: 0;

    .icon {
      display: none;
    }
  }
}

// Ensure windows are displayed above the navbar
.navbar-static-top {
  z-index: 1;
}
#tabs {
  z-index: 100;
}
