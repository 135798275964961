/*
 * filter-box-icon.less - Replaces the Filter text with an icon
 */

@import 'mixins/buttons';
@import 'mixins/tab-focus';

@icon-color-escaped: escape(@icon-color);

// Leave a gap between the filter button and the search entry
.filter-box .input-group-btn:first-child > .btn {
  margin-right: max(@filter-box-padding * 2, @padding-base-horizontal);
}

// Make all the filter button's corners rounded
.filter-box .input-group-btn:first-child > .btn {
  border-radius: @btn-border-radius-small;
}

// Use a custom icon instead of the filters text
.filter-box .input-group-btn:first-child > .btn {
  background-position: center center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="@{icon-color-escaped}" d="M15,19.88C15.04,20.18 14.94,20.5 14.71,20.71C14.32,21.1 13.69,21.1 13.3,20.71L9.29,16.7C9.06,16.47 8.96,16.16 9,15.87V10.75L4.21,4.62C3.87,4.19 3.95,3.56 4.38,3.22C4.57,3.08 4.78,3 5,3V3H19V3C19.22,3 19.43,3.08 19.62,3.22C20.05,3.56 20.13,4.19 19.79,4.62L15,10.75V19.88M7.04,5L11,10.06V15.58L13,17.58V10.05L16.96,5H7.04Z"/></svg>');
  background-repeat: no-repeat;
  background-size: @line-height-computed;
  text-indent: @input-height-small;
  overflow: hidden;
  width: @input-height-small;
}

// Make the filter button square
.filter-box .input-group-sm > .input-group-btn > button.btn {
  width: @input-height-small;
}

// Make the filter button appear like a button
.filter-box .btn-link {
  background-color: @btn-default-bg;
  border: 1px solid @btn-default-border;
  border-radius: @btn-border-radius-base;
  color: @toolbar-icon-color;
  opacity: 1;

  &:active,
  &.active {
    background-color: darken(@btn-default-bg, 10%);
    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));

    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    background-color: darken(@btn-default-bg, 10%);
    text-decoration: none;
  }
}

// Round all the corners of the filter box
.filter-box .input-group .form-control {
  border-radius: @input-border-radius !important;
}
