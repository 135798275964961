/*
 * tree.less - Style tree views
 */

// Remove the margin below tables
.table {
  margin-bottom: 0;
}

// Add a dividing line at the bottom of the table
.table {
  border-bottom: 1px solid @table-border-color;
}

// Horizonally scroll the tabs if they overflow
.tab-domain {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

// Ensure the tabs do not wrap
.tab-domain {
  white-space: nowrap;

  > .nav-tabs > li {
    display: inline-block;
    float: none;
  }
}

// Add some padding around the tabs
.tab-domain > .nav-tabs {
  margin: 0;
  padding-top: @nav-tabs-padding-vertical;
  padding-bottom: @nav-tabs-padding-vertical;
  padding-left: @nav-tabs-padding-horizontal;
  padding-right: @nav-tabs-padding-horizontal;  
}

// Try and add some border below off screen tabs
.tab-domain > .nav-tabs > li:not(.active) > a {
  border-bottom: 1px solid @nav-tabs-border-color;
}

// Move the counters to the top right of the tabs
.tab-domain > .nav-tabs .badge {
  font-size: @font-size-tiny;
  min-width: auto;
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 4;

  &:not([title="0"]) {
    background-color: @brand-danger;
  }
}

// Hide the counters if they are zero
.tab-domain > .nav-tabs .badge[title="0"] {
  display: none;
}

// Ensure columns with checkboxes are not too small
.tree {
  col.boolean:not([style*="width: 0px"]),
  col.selection-state {
    width: @line-height-computed + (@table-condensed-cell-padding * 2) !important;
  }
}

// Remove the artificial limit to the treeview height
.treeview {
  height: auto !important;
}

// Remove top and bottom borders from table-bordered elements
.table-bordered {
  border-top: none;
  border-bottom: none;
}

// Try and make sure buttons don't expand the height of a line
.tree .btn {
  font-size: @font-size-tiny;
}

// Allow the text to fill more of the space on the button
.tree .btn {
  padding-left: @padding-xs-horizontal;
  padding-right: @padding-xs-horizontal;
}

// Ensure buttons have a border
.tree .btn {
  border-color: @btn-primary-border;
}

// Make treeview header and footers solid white
.treeview > table.tree {
  > thead > tr:first-child th {
    background-color: #fff;
  }
  > tfoot > tr th {
    background-color: #fff;
  }
}

// Style the column headings
.treeview > table.tree {
  > thead > tr:first-child th {
    color: @gray-darker;
    font-variant: all-small-caps;
    font-size: 90%;
    font-weight: bold;
  }
}

// Remove the top scrollbar
.screen-container > .content-box .scrollbar.responsive {
  display: none;
}

// Make the expander larger
.treeview .expander > img {
  width: 1.25em;
}

// Don't display the selection-state column on small screens
@media (max-width: @screen-xs-max) {
  .tree col.selection-state {
    width: 0 !important;
  }
}

// Don't display the selection-state column in wizards
.wizard .tree col.selection-state {
  width: 0 !important;
}

// Disable links on many2one fields
.tree .column-many2one > a {
  pointer-events: none;
  cursor: default;
  color: @text-color;
}

// Ensure the optional column list is visible
.treeview > table.tree > thead > tr > th.optional {
  overflow: unset;
}

