/*
 * calendar.less - Calendar specific styles
 */

@text-color-escaped: escape(@text-color);

.calendar {
  // Add a standard margin around the calendar toolbar
  > .fc-toolbar {
    margin: @padding-base-horizontal;
  }

  // Remove the scrollbar from week view
  .fc-time-grid {
    overflow: hidden;
  }

  // Display the toolbar as a full width column
  .fc-toolbar {
    display: flex;
    flex-direction: column;

    .fc-left {
      order: 1;
    }

    .fc-center {
      order: 2;
      display: flex;
      justify-content: center;

      h2 {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 1em;
        margin-right: 1em;
      }

      button {
        margin: 0;
      }
    }

    .fc-right {
      order: 3;
      display: flex;
      justify-content: space-around;

      button {
        margin: 0.5em;
      }
    }
  }

  // Hide the today button
  .fc-left {
    display: none;
  }

  // Set the size of the title
  .fc-center h2 {
    font-size: @font-size-large;
  }

  // Style the calendar view selectors as nav-tabs
  .fc-right {
    .btn {
      height: unset;
      padding: @nav-link-padding;
    }

    .active,
    .active:hover,
    .active:focus {
      background-color: @nav-tabs-active-link-hover-bg;
      border: 1px solid @nav-tabs-active-link-hover-border-color;
      color: @nav-tabs-active-link-hover-color;
    }
  }

  // Use standard thickness border below table header
  .table-bordered > thead > tr > td,
  .table-bordered > thead > tr > th {
    border-bottom-width: 1px;
  }

  // Remove the gap between all day events and the rest of the events
  .table-bordered >tbody > tr > td > .fc-divider {
    border-bottom-style: unset;
    padding: 0;
  }

  // Ensure calendar numbers are in the top corner
  .fc-body {
    line-height: 1;
  }

  // Add some spacing around empty list text
  .fc-list-empty {
    padding: @padding-large-horizontal;
  }
  .fc-list-empty-wrap2 {
    position: unset;
  }

  // Replace left and right icons with feather icons
  .fc-prev-button,
  .fc-next-button {
    background-position: center center;
    background-repeat: no-repeat;

    > .glyphicon {
      visibility: hidden;
    }
  }
  .fc-prev-button {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="@{text-color-escaped}" d="m 14.980469,4.9902344 a 1.0001,1.0001 0 0 0 -0.6875,0.3027344 L 8.2929688,11.292969 a 1.0001,1.0001 0 0 0 0,1.414062 l 6.0000002,6 a 1.0001,1.0001 0 1 0 1.414062,-1.414062 L 10.414062,12 15.707031,6.7070312 A 1.0001,1.0001 0 0 0 14.980469,4.9902344 Z"/></svg>');
  }
  .fc-next-button {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="@{text-color-escaped}" d="M 8.9902344,4.9902344 A 1.0001,1.0001 0 0 0 8.2929688,6.7070312 L 13.585938,12 8.2929688,17.292969 a 1.0001,1.0001 0 1 0 1.4140624,1.414062 l 5.9999998,-6 a 1.0001,1.0001 0 0 0 0,-1.414062 L 9.7070312,5.2929688 A 1.0001,1.0001 0 0 0 8.9902344,4.9902344 Z"/></svg>');
  }
}
